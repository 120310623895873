$ma-primary: #283480;
$ma-secondary: #87AD1C;

// Override default variables before the import
$blue: $ma-primary;
$green: $ma-secondary;
$body-bg: #eee;

$enable-rounded: false;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
    position: relative;
}

.blocker {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
    display: block;
    z-index: 10000;
}
.blocker--loader {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10001;
    opacity: 1;
    span {
        background-color: $ma-primary;
        font-size: 1em;
        color: #fff;
        text-align: left;
        display: block;
        text-align: center;
        padding: 15px 20px;
        margin: 0 auto;
        position: relative;
    }
}

.alert {
    &.top__alert {
        position: fixed;
        width: 100%;
        z-index: 10002;
        margin-bottom: 0;
    }
    a {
        color: inherit;
        font-weight: bold;
        border-bottom: 1px dashed;
        &:hover {
            text-decoration: none;
        }
    }
}

mark {
    padding: 0;
    font-weight: bold;
}

.card__details > * {
    font-size: 0.9em;
}

input[type="checkbox"] {
    position: relative;
    @extend .ml-0, .mr-1;
}

.custom-switch {
    padding: 0.2em 1em 0 2em;
    input[type=checkbox] {
        display: none;
    }
}

.paginator {
    .pagination {
        display: -ms-flexbox;
        flex-wrap: wrap;
        display: flex;
        padding: 20px 20px 0;
        list-style: none;
        border-radius: 0.25rem;
    }
}

.or__text {
    p {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        font-size: 0.75em;
        line-height: 0.1em;
        margin: 20px 0;
    }
    p span {
        background:#fff;
        padding:0 10px;
    }
}

.hidden {
    display: none;
}

.reload__button {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}

.customer__acount__details {
    tbody {
        tr {
            th {
                text-align: right;
            }
        }
    }
}